<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Registro água e luz / {{ $route.params.id ? 'Editar' : 'Novo' }}
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-4">
                            <label for="data" class="block text-sm font-medium">Data</label>
                            <datepicker v-model="form.data" :language="ptBR" name="data" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="obra" class="block text-sm font-medium">Obra</label>
                            <select v-model="form.obra" id="obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option v-for="obra in obras" :key="obra._id" :value="obra._id" ><span v-if="$store.state.user.root">{{ obra.empresa.nome }} - </span>{{ obra.nome }}</option>
                            </select>
                        </div> 
                        <div class="col-span-12 md:col-span-4">
                            <label for="tipo" class="block text-sm font-medium">Tipo</label>
                            <select v-model="form.tipo" id="tipo" name="tipo" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option value="Água">Água</option>
                                <option value="Luz">Luz</option>
                            </select>
                        </div> 
                    </div>
                </div>

                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-4">
                            <label for="leitura" class="block text-sm font-medium">Leitura Inicial</label>
                            <input v-model="form.leitura" type="number" name="leitura" id="leitura" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="leituraFinal" class="block text-sm font-medium">Leitura Final</label>
                            <input v-model="form.leituraFinal" type="number" name="leituraFinal" id="leituraFinal" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="totalNoite" class="block text-sm font-medium">Consumo noite</label>
                            <input v-model="form.totalNoite" type="number" name="leituraFinal" id="totalNoite" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-12">
                            <label for="obs" class="block text-sm font-medium">Observação</label>
                            <textarea v-model="form.obs" name="obs" id="obs" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                        </div>
                    </div>
                </div>
                
                <div class="grid grid-cols-2 grap-6">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                            Salvar
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

export default {
    data() {
        return {
            ptBR: ptBR,
            route: 'agualuz',
            form: {
                obra: null,
                tipo: '',
                leitura: '',
                leituraFinal: '',
                totalNoite: '',
                data: '',
            },
            obras: [],
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    components: {
        Datepicker
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }

        const reqobras = await this.$http.post('/v1/obras/list');
        this.obras = reqobras.data.data;
    },
}
</script>